import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {
  images = [
    {
      text: 'Telecom',
      img: '../../assets/images/industries/communication.png'
    },
    {
      text: 'Government',
      img: '../../assets/images/industries/government.png'
    },
    {
      text: 'Media and entertainment',
      img: '../../assets/images/industries/entertainment.png'
    },
    {
      text: 'Retail',
      img: '../../assets/images/industries/Retail.png'
    },
    {
      text: 'Manufacturing',
      img: '../../assets/images/industries/manufacturing.png'
    },
    {
      text: 'Education',
      img: '../../assets/images/industries/education.png'
    }
  ]
  constructor() {
  }

  ngOnInit() {

  }






}
