import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-isan-data',
  templateUrl: './why-isan-data.component.html',
  styleUrls: ['./why-isan-data.component.css']
})
export class WhyIsanDataComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  paymentTypeList: any[] = [
    {
      title: 'Pay as you go',
      imagepath: './../../assets/images/support-16.png',
      description:
        'Variable pricing model on the basis of selected services for your modular needs.'
    },
    {
      title: 'Monthly Subscription',
      imagepath: './../../assets/images/ic_credit_card_48px.png',
      description:
        'Simple monthly plans which include a fixed amount of billable hrs that can be used for any Infrastructure & Cloud services'
    },
    {
      title: 'Reliable',
      imagepath: './../../assets/images/ic_whatshot_48px.png',
      description:
        'Our experts  provide seamless customer experience, actionable insights, and optimum business efficiency.'
    },
    {
      title: 'Global Support',
      imagepath: './../../assets/images/ic_public_48px.png',
      description:
        'We support our clients with offices in India and Singapore and 24/7 online support'
    }
  ];
}
