import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {} from '@angular/platform-browser';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css'],
  animations: [
    trigger('animate', [
      state(
        ':leave',
        style({
          height: 0
        })
      ),
      state(
        ':enter',
        style({
          height: 'auto'
        })
      ),
      transition('* => *', animate(1000))
    ])
  ]
})
export class CareersComponent implements OnInit {
  requirement: any = {
    requirements: [],
    offer: [],
    responsibility: [],
    location: ''
  };
  
  selectedJob: any;
  @ViewChild('closeBtn', { static: true }) closeBtn: ElementRef;
  openings: any = [
    {
      title: 'Cloud Engineer',
      intro:
        'ISAN is seeking a Cloud Engineer who thrives working in a fast paced state of the art Cloud environment. This position will be heavily involved with the migration of our existing products, as well as the development of new products withi our cloud platform',
      openings: '1',
      noOfExp: '1+',
      requirement: {
        requirements: [
          '1-2years experience working in an AWS Environment (required).',
          'Experience with secure design & architecture in a Dynamic Cloud Environment.',
          'Writing and enforcement of security policies in a cloud environment.',
          "Bachelor's degree in Computer Science or related field or relevant experience."
        ],
        offer: [
          'A culture and work environment strongly reflecting our core values: Act like an Owner, Delight Our Customers and Earn the Respect of Others.',
          'The opportunity to make a difference by leveraging your unique strengths.',
          'Highly competitive compensation and rewards.',
          'Flexible work options, opportunities to give back to your community, social events with colleagues and a comprehensive benefits program inclusive of progressive parental leave.'
        ],
        responsibility: [
          'Collaborate with security teams to define security standards for AWS.',
          'Ensure suitable tooling and functionality is available to meet security requirements in the AWS environment.',
          'Connect with product teams to provide expert advice on migrating existing products into an AWS environment in a secure manner.',
          'Provide AWS Best practice security guidance to all.',
          'Assist with Infrastructure Engineering team to design and maintain an appropriate AWS shared Infrastructure component, and in particular with securing the environment.',
          'Provide guidance on delivering solutions that deliver cross platform compatibility in a secure manner.'
        ],
        location: 'Banglore'
      }
    },
    {
      title: 'Storage Engineer',
      intro:
        'ISAN  is seeking a storage engineer who thrives working in a fast paced environment. This position will be heavily involved with the migration of our existing products, as well as the development of new product.',
      openings: '1',
      noOfExp: '1+',
      requirement: {
        requirements: [
          '0 to 2years of experience working in dynamic, face-paced industry (similar to Media and Entertainment).',
          'Excellent communications and interpersonal skills.',
          'Ability to perform effectively in a fast-paced environment with short timelines.',
          'Ability to maintain a professional and courteous manner in difficult situations.',
          'Ability to maintain high-quality work to manage multiple critical projects.',
          'Ability to perform and participate in a team-oriented environment.',
          'Ability to adjust priorities to align to changing demands of business units.'
        ],
        responsibility: [
          'Over 0 to 2 years hands on experience in a technical storage infrastructure operational role Technical understanding of storage technologies including Dell EMC, Client, Qumulo and NetApp',
          'Responsible for the engineering. implementing, maintaining, troubleshooting and supporting of enterprise grade data storage and protection systems for structured and unstructured data',
          'High-performance Block Storage Systems (similar to Pure) in an enterprise environment.',
          'FC Switching (similar to Brocade) in an enterprise environment.',
          'File Storage Systems (similar to NetApp) in an enterprise environment.',
          'Object Storage Systems (On-Premise and Public Cloud) in an enterprise environment.',
          'Archive Storage Systems (similar to Rubrik) in an enterprise environment.',
          'Data life-cycle management and tiering strategies.',
          'Collaborate with SME`s from various IT disciplines to form comprehensive solutions to meet or exceed design criteria specified by diverse business units',
          'Demonstrate ability to succeed in a large global company with a complex IT environment. Ability to manage own workload and deliver results in fast paced, demanding environment with changing priorities.',
          'Work to ITIL best practices in regard to incident management, change management, configuration and problem management.',
          'Manage services to SLA. Ensure all severity 1 issues are acted on to SLA. Work with major incident managers and peers from other groups to resolve major incidents to resolution.',
          'Participate in on-call rotation for out of hours support related to supporting storage infrastructure globally',
          'Work proactively to manage performance of the storage technologies and avoid issues related to performance',
          'Candidate selected will have to travel several times throughout the year to other business locations',
          'Advanced process training and certifications highly desirable such as ITIL v3 or 4, Kepner Tregoe Decision Analysis & Problem Solving'
        ],
        location: 'Mumbai'
      }
    },
    {
      title: 'Freshers / Internship',
      noOfExp: '0+',
      openings: '1+',
      intro:
        'ISAN DATA SYSTEMS help you to build the future. As part of this program, you’ll gain wide experience to our IT function while working in areas like cloud computing, application development, network designing, Storage Consulting and infrastructure consulting.',
      requirement: {
        requirements: ['Any Technical Graduate'],
        location: 'Bangalore / Mumbai'
      }
    }
  ];
  showRequirements(job) {
    if (this.selectedJob == job) {
      this.selectedJob = undefined;
    } else {
      this.selectedJob = job;
    }
    for (let i = 0; i < this.openings.length; i++) {
      if (job.title == this.openings[i].title) {
        this.requirement = this.openings[i].requirement;
      }
    }
  }
  constructor() {}

  ngOnInit() {}
}
