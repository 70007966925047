import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { RefundComponent } from './refund/refund.component';



import { ServicesComponent } from './services/services.component';
import { ServiceListDisplayComponent } from './service-list-display/service-list-display.component';
import { ServiceListDescriptionComponent } from './service-list-description/service-list-description.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PaymentPlanComponent } from './payment-plan/payment-plan.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'aboutus', component: AboutUsComponent },
  { path: 'conditions', component: ConditionsComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'refund', component: RefundComponent },


  { path: 'myaccount', component: MyAccountComponent },
  { path: 'paymentplan', component: PaymentPlanComponent },
  {
    path: 'service',
    component: ServicesComponent,

    children: [
      { path: '', redirectTo: 'cloudComputing', pathMatch: 'full' },
      {
        path: 'cloudComputing',
        component: ServiceListDisplayComponent,
        children: [
          {
            path: '',
            redirectTo: 'cloudAssessmentStrategy',
            pathMatch: 'full'
          },
          {
            path: 'cloudAssessmentStrategy',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'cloudMigration',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'cloudOperations',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'cloudSecurityGovernance',
            component: ServiceListDescriptionComponent
          },
          { path: 'cloudFinOps', component: ServiceListDescriptionComponent },
          {
            path: 'devOpsAssessment',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'devOpsAutomation',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'devOpsManagement',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'kubernetesConsulting',
            component: ServiceListDescriptionComponent
          }
        ]
      },
      {
        path: 'infrastructure',
        component: ServiceListDisplayComponent,
        children: [
          { path: '', redirectTo: 'hyperCoveraged', pathMatch: 'full' },
          {
            path: 'hyperCoveraged',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'storageConsulting',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'networkConsulting',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'virtualizationConsulting',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'dataProtectionServices',
            component: ServiceListDescriptionComponent
          }
        ]
      },
      {
        path: 'application',
        component: ServiceListDisplayComponent,
        children: [
          {
            path: '',
            redirectTo: 'websiteDesignAndApplication',
            pathMatch: 'full'
          },
          {
            path: 'websiteDesignAndApplication',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'mobileApplication',
            component: ServiceListDescriptionComponent
          },
          { path: 'UIUXDesign', component: ServiceListDescriptionComponent },
          {
            path: 'eCommerceSolutions',
            component: ServiceListDescriptionComponent
          }
        ]
      },
      {
        path: 'digitalService',
        component: ServiceListDisplayComponent,
        children: [
          { path: '', redirectTo: 'artificialIntelligence', pathMatch: 'full' },
          {
            path: 'artificialIntelligence',
            component: ServiceListDescriptionComponent
          },
          {
            path: 'machineLearning',
            component: ServiceListDescriptionComponent
          },
          { path: 'iot', component: ServiceListDescriptionComponent },
          {
            path: 'digitalMarketing',
            component: ServiceListDescriptionComponent
          }
        ]
      }
    ]
  }
  // { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
