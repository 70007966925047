import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";
import { Router } from "@angular/router";
import { AppServiceService } from "./../app-service.service";
@Component({
  selector: "app-pricing-of-home-page",
  templateUrl: "./pricing-of-home-page.component.html",
  styleUrls: ["./pricing-of-home-page.component.css"],
  animations: [
    trigger("fadeInOut", [
      state(
        "void",
        style({
          opacity: 0,
          height: 0
        })
      ),
      transition("void => *", animate("1.2s 1s")),
      transition("*=>void", animate("1s"))
    ])
  ]
})
export class PricingOfHomePageComponent implements OnInit {
  isChecked: boolean;
  constructor(private router: Router, private appService: AppServiceService) {}

  ngOnInit() {}

  gridData: any[] = [];
  monthlyPaymentModeGridData: any[] = [
    {
      membership: "Silver",
      price: "$700",
      perMonth: "<b>10</b> hrs per month"
    },
    {
      membership: "Gold",
      price: "$1300",
      perMonth: "<b>20</b> hrs per month"
    },

    {
      membership: "Platinum",
      price: "$2400",
      perMonth: "<b>40</b> hrs per month"
    }
  ];
  payAsYouGoPaymentModeGridData: any[] = [
    {
      membership: "Pay as you go",
      price: "$120"
    }
  ];
  changePaymentMode(isPayAsYouGoPaymentMode) {
    if (isPayAsYouGoPaymentMode) {
      this.gridData = this.payAsYouGoPaymentModeGridData;
    } else {
      this.gridData = this.monthlyPaymentModeGridData;
    }
  }

  goToPaymentPage(membershiptype) {
    this.appService.selectedMembershipObj = membershiptype;
    this.router.navigateByUrl("/paymentplan");
  }
}
