import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  connectionList: any[] = [
    { name: 'facebook', imageSrc: './../../assets/images/001-youtube.png' },
    { name: 'facebook', imageSrc: './../../assets/images/008-twitter.png' },
    { name: 'facebook', imageSrc: './../../assets/images/029-instagram.png' },
    { name: 'facebook', imageSrc: './../../assets/images/036-facebook.png' }
  ];

  aboutList: any[] = [
    { title: 'Who are we', href: '/aboutus#who-we-are' },
    { title: 'Why Isan', href: '/aboutus#why-isan' },
    { title: 'Story', href: '/aboutus#story' },
    { title: 'Leadership Team', href: '/aboutus#team' }

  ];
  conditions: any[] = [
    { title: 'Privacy policy', href: '/conditions#privacy-policy' }

  ];
  terms: any[] = [
  { title: 'Terms & Conditions', href: '/terms#terms-conditions' }

  ];

  policy: any[] = [
  { title: 'Disclaimer Policy', href: '/policy#disclaimer-policy' }

  ];

  refund: any[] = [
      { title: 'Refund & Cancellation Policy', href: '/refund#refund-cancellation-policy' }


  ];


  serviceList: any[] = [
    { title: 'Cloud Computing', href: '/service/cloudComputing' },
    { title: 'Infrastructure Service', href: '/service/infrastructure' },
    { title: 'Digital Service', href: '/service/digitalService' },
    { title: 'Application Service', href: '/service/application' }
  ];
  year = new Date().getFullYear();
  constructor() {}

  ngOnInit() {}
}
