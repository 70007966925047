import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-of-home-page',
  templateUrl: './service-of-home-page.component.html',
  styleUrls: ['./service-of-home-page.component.css']
})
export class ServiceOfHomePageComponent implements OnInit {
  services1: any[] = [
    {
      title: 'Cloud assessment & strategy',
      url: '/service/cloudComputing/cloudAssessmentStrategy',
      src: './../../assets/images/servies/023-search.png'
    },
    {
      title: 'Cloud migration',
      url: '/service/cloudComputing/cloudMigration',
      src: './../../assets/images/servies/013-database.png'
    },
    {
      title: 'Cloud operations',
      url: '/service/cloudComputing/cloudOperations',
      src: './../../assets/images/servies/017-cloud.png'
    },
    {
      title: 'Cloud security & governance',
      url: '/service/cloudComputing/cloudSecurityGovernance',
      src: './../../assets/images/servies/007-security.png'
    },
    {
      title: 'Cloud FinOps',
      url: '/service/cloudComputing/cloudFinOps',
      src: './../../assets/images/servies/039-computing.png'
    },
    {
      title: 'DevOps Assessment',
      url: '/service/cloudComputing/devOpsAssessment',
      src: './../../assets/images/servies/014-analytics.png'
    }
  ];
  services2: any[] = [
    {
      title: 'DevOps Automation',
      url: '/service/cloudComputing/devOpsAutomation',
      src: './../../assets/images/servies/008-syncronization.png'
    },
    {
      title: 'DevOps management',
      url: '/service/cloudComputing/devOpsManagement',
      src: './../../assets/images/servies/036-big data.png'
    },
    {
      title: 'Kubernetes Consulting',
      url: '/service/cloudComputing/kubernetesConsulting',
      src: './../../assets/images/servies/009-folder.png'
    }
  ];
  constructor() {}

  ngOnInit() {}
}
