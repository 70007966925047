import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppServiceService } from './../app-service.service';
import { Router } from '@angular/router';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.css']
})
export class PaymentPlanComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  token: any;
  @ViewChild('closeBtn', { static: true }) closeBtn: ElementRef;
  isPaymentSelected: any = true;
  isAddServiceSelected: any = false;
  isStepCompleted: any = false;
  selectedMembership: any = '';
  taxes: any = 0;
  userDetails: any;
  membershipObject: any;
  serviceQuantity: any = 1;
  payAsYouGoQuantity: any = 1;
  totalPrice: any = 0;
  totalPriceForPayAsYouGo: any = 0;
  selectFromService: any;
  payAsYouGoSubTotal: any = 0;
  selectedServiceDetailsArray: any[] = [];
  servicesToBeSelectedFromArray: any[] = [];
  isCheckoutDone: boolean = false;
  showSuccessMessage: boolean = false;
  itemsSelectedToCheckout: any[] = [];
  monthlyPaymentModeGridData: any[] = [
    {
      membership: 'Silver',
      price: '700',
      perMonth: '10 hrs per month',
      value: 1,
      checked: false
    },
    {
      membership: 'Gold',
      price: '1300',
      perMonth: '20 hrs per month',
      value: 2,
      checked: false
    },
    {
      membership: 'Platinum',
      price: '2400',
      perMonth: '40 hrs per month',
      value: 3,
      checked: false
    },
    {
      membership: 'Pay as you go',
      price: '12',
      value: 4,
      checked: false
    }
  ];

  constructor(
    public appService: AppServiceService,
    private router: Router,
    private httpClient: HttpClient
  ) {}
  setCalledFromVar() {
    this.appService.loginModalCalledFrom = 'payment';
  }

  ngOnInit() {
    this.token = localStorage.getItem('session_token');
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.initConfig();
    if (localStorage.getItem('cancelledPaymntObj') != undefined) {
      var obj = JSON.parse(localStorage.getItem('cancelledPaymntObj'));
      this.isPaymentSelected = false;
      this.membershipObject = obj.membershipObj;
      this.selectedServiceDetailsArray = obj.payAsYouGoArray;
      this.selectedMembership = obj.selectedMembership;
      this.taxes = obj.taxes;
      this.serviceQuantity = obj.serviceQuantity;
      this.selectedMembership == 'Pay as you go'
        ? (this.totalPriceForPayAsYouGo = obj.totalPrice)
        : (this.totalPrice = obj.totalPrice);
      this.selectedServiceDetailsArray =
        this.selectedMembership == 'Pay as you go' ? obj.payAsYouGoArray : [];
      if (this.selectedServiceDetailsArray.length > 0) {
        this.payAsYouGoSubTotal = 0;
        for (let i = 0; i < this.selectedServiceDetailsArray.length; i++) {
          this.payAsYouGoSubTotal +=
            parseFloat(this.selectedServiceDetailsArray[i].price) *
            this.selectedServiceDetailsArray[i].quantity;
        }
      }
    } else if (this.appService.selectedMembershipObj.membership != undefined) {
      this.selectedMembership = this.appService.selectedMembershipObj.membership;
      for (let i = 0; i < this.monthlyPaymentModeGridData.length; i++) {
        if (
          this.monthlyPaymentModeGridData[i].membership !=
          this.selectedMembership
        ) {
          this.monthlyPaymentModeGridData[i].checked = false;
        } else {
          this.membershipObject = this.monthlyPaymentModeGridData[i];
          this.monthlyPaymentModeGridData[i].checked = true;
        }
      }
      this.taxes = this.membershipObject.price * this.serviceQuantity * 0.18;
      this.taxes = parseFloat(this.taxes).toFixed(2);
      this.totalPrice =
        this.membershipObject.price * this.serviceQuantity +
        parseFloat(this.taxes);
    } else {
      this.selectedServiceDetailsArray = [];
    }

    window.scroll(0, 0);
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId:
        'AVvaAfIbWMOEP4A1FAEzeHMFr-1MZkXheN0HrNMRQQzLL1FdQQ041oDhp_ii3f9scS6tk9KhXdaYimjU',
      // clientId: "AbRAwbX2VX94DPCfiUL_iJYTSbYO2hyISZZ_fOZ07P2uPgoCDL9WI68JfFFX72IqgNjMmkqr4CCPL9-M",
      createOrderOnClient: data =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value:
                  this.selectedMembership == 'Pay as you go'
                    ? this.totalPriceForPayAsYouGo
                    : this.totalPrice,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value:
                      this.selectedMembership == 'Pay as you go'
                        ? this.totalPriceForPayAsYouGo
                        : this.totalPrice
                  }
                }
              },
              items: this.itemsSelectedToCheckout
            }
          ]
        },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
      
        actions.order.get().then(details => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: data => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        this.showSuccessMessage = true;

        if (this.selectedMembership == 'Pay as you go') {
          let services = [];
          let subtotal = 0;
          var fd: any;
          for (let i = 0; i < this.selectedServiceDetailsArray.length; i++) {
            let obj = {
              service_name: this.selectedServiceDetailsArray[i].name,
              price_per_hour: this.membershipObject.price,
              no_of_hour: this.selectedServiceDetailsArray[i].quantity,
              total_serice_price:
                this.membershipObject.price *
                this.selectedServiceDetailsArray[i].quantity
            };
            services.push(obj);
            subtotal +=
              this.membershipObject.price *
              this.selectedServiceDetailsArray[i].quantity;
          }
          fd = {
            user_id: this.userDetails.userdata.userid,
            tokken: this.token,
            plan_type: 1,
            service: services,
            sub_total: subtotal,
            tax: this.taxes,
            total: this.totalPriceForPayAsYouGo
          };
        } else {
          let planid =
            this.selectedMembership == 'Silver'
              ? 0
              : this.selectedMembership == 'Gold'
              ? 1
              : 2;
          fd = {
            user_id: this.userDetails.userdata.userid,
            tokken: this.token,
            plan_type: 0,
            plan_id: planid,
            no_of_months: this.serviceQuantity,
            price_per_month: this.membershipObject.price,
            sub_total: this.membershipObject.price * this.serviceQuantity,
            tax: this.taxes,
            total: this.totalPrice
          };
        }

        let apiURL = environment.apiUrl;
        this.httpClient.post<any>(apiURL + 'booking', fd).subscribe(
          data => {
            if (data.status == true) {
              console.log('booking done');
              if (localStorage.getItem('cancelledPaymntObj') != undefined) {
                localStorage.removeItem('cancelledPaymntObj');
              }
              this.router.navigateByUrl('/');
            } else {
              console.log('Error', data);
            }
          },
          error => {
            console.log('Error', error);
          }
        );
      },
      onCancel: (data, actions) => {
        var obj = {
          membershipObj: this.membershipObject,
          taxes: this.taxes,
          serviceQuantity: this.serviceQuantity,
          selectedMembership: this.selectedMembership,
          payAsYouGoArray: this.selectedServiceDetailsArray,
          totalPrice:
            this.selectedMembership == 'Pay as you go'
              ? this.totalPriceForPayAsYouGo
              : this.totalPrice
        };
        localStorage.setItem('cancelledPaymntObj', JSON.stringify(obj));
        actions.redirect(location.href);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        this.closeBtn.nativeElement.click();
      }
    };
  }

  onlistItemClicked(tabSelected: any) {
    this.isPaymentSelected = tabSelected;
  }

  onBackButtonClick() {
    this.isPaymentSelected = true;
    this.isAddServiceSelected = false;
    this.membershipObject = {};
    this.itemsSelectedToCheckout = [];
    this.serviceQuantity = 1;
    for (let i = 0; i < this.monthlyPaymentModeGridData.length; i++) {
      this.monthlyPaymentModeGridData[i].checked = false;
    }
    if (localStorage.getItem('cancelledPaymntObj') != undefined) {
      localStorage.removeItem('cancelledPaymntObj');
    }
  }

  onCheckoutButtonClick(membershipType: any) {
    this.isCheckoutDone = true;
    if (membershipType == 'monthlyMembership') {
      let item = {
        name: this.selectedMembership,
        quantity: this.serviceQuantity,
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'USD',
          value: this.membershipObject.price
        }
      };
      this.itemsSelectedToCheckout.push(item);
      let taxitem = {
        name: 'tax',
        quantity: 1,
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'USD',
          value: this.taxes
        }
      };
      this.itemsSelectedToCheckout.push(taxitem);
    } else if (membershipType == 'payAsYouGo') {
      for (let i = 0; i < this.selectedServiceDetailsArray.length; i++) {
        var total_pay_price = 0;
        let value;

        let tempitem = {
          name: this.selectedServiceDetailsArray[i].name,
          quantity: this.selectedServiceDetailsArray[i].quantity,
          category: 'DIGITAL_GOODS',
          unit_amount: {
            currency_code: 'USD',
            value: this.selectedServiceDetailsArray[i].price
          }
        };
        this.itemsSelectedToCheckout.push(tempitem);
      }
      let taxitem = {
        name: 'tax',
        quantity: 1,
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'USD',
          value: this.taxes
        }
      };
      this.itemsSelectedToCheckout.push(taxitem);
    }
  }
  initialSelectedServiceForAddService: any = [];
  addService() {
    this.isAddServiceSelected = true;
    this.selectFromService = 'cloudComputing';
    this.servicesToBeSelectedFromArray = this.appService.getServiceListToDisplay(
      this.selectFromService
    );
    for (let i = 0; i < this.servicesToBeSelectedFromArray.length; +i++) {
      this.servicesToBeSelectedFromArray[i]['isChecked'] = false;
    }
    this.initialSelectedServiceForAddService = JSON.parse(
      JSON.stringify(this.selectedServiceDetailsArray)
    );
    if (this.selectedServiceDetailsArray.length > 0) {
      for (let i = 0; i < this.servicesToBeSelectedFromArray.length; +i++) {
        for (let j = 0; j < this.selectedServiceDetailsArray.length; j++) {
          if (
            this.servicesToBeSelectedFromArray[i].name ==
            this.selectedServiceDetailsArray[j].name
          ) {
            this.servicesToBeSelectedFromArray[i]['isChecked'] = true;
          }
        }
      }
    }
  }
  removeFromList(serviceToBeRemoved) {
    for (let i = 0; i < this.selectedServiceDetailsArray.length; i++) {
      if (this.selectedServiceDetailsArray[i].name == serviceToBeRemoved.name) {
        this.selectedServiceDetailsArray.splice(i, 1);
      }
    }
    this.calculateTotalPriceForPayAsYouGo();
    if (this.selectedServiceDetailsArray.length == 0) {
      for (let i = 0; i < this.servicesToBeSelectedFromArray.length; +i++) {
        this.servicesToBeSelectedFromArray[i]['isChecked'] = false;
      }
      this.onBackButtonClick();
    }
  }

  selectFromServiceMethod(serviceName: any) {
    this.selectFromService = serviceName;
    this.servicesToBeSelectedFromArray = this.appService.getServiceListToDisplay(
      this.selectFromService
    );
  }
  onCheckMembershipType(
    event: any,
    membership: any,
    clickedFromCheckbox: boolean
  ) {
    this.selectedMembership = membership;
    let isChecked: any;
    if (clickedFromCheckbox) {
      isChecked = event.target.checked;
    } else {
      for (let i = 0; i < this.monthlyPaymentModeGridData.length; i++) {
        if (this.monthlyPaymentModeGridData[i].membership == membership) {
          isChecked = !this.monthlyPaymentModeGridData[i].checked;
        }
      }
    }

    for (let i = 0; i < this.monthlyPaymentModeGridData.length; i++) {
      if (this.monthlyPaymentModeGridData[i].membership != membership) {
        this.monthlyPaymentModeGridData[i].checked = false;
      } else {
        this.membershipObject = this.monthlyPaymentModeGridData[i];
        this.monthlyPaymentModeGridData[i].checked = isChecked;
      }
    }

    this.taxes = this.membershipObject.price * this.serviceQuantity * 0.18;
    this.taxes = parseFloat(this.taxes).toFixed(2);
    this.totalPrice =
      this.membershipObject.price * this.serviceQuantity +
      parseFloat(this.taxes);
  }

  goToReviewAndCheckoutPage() {
    window.scroll(0, 0);
    this.isAddServiceSelected = false;
    this.isPaymentSelected = false;
    this.calculateTotalPriceForPayAsYouGo();
  }

  onServiceSelectedForPayAsYouGo(
    event: any,
    service: any,
    clickedFromCheckbox: boolean
  ) {
    if (service.name != 'Digital Marketing') {
      if (clickedFromCheckbox) service.isChecked = event.target.checked;
      else {
        service.isChecked = !service.isChecked;
      }
      if (service.isChecked) {
        service.quantity = 1;
        this.selectedServiceDetailsArray.push(service);
      } else if (!service.isChecked) {
        this.selectedServiceDetailsArray.forEach((element, index) => {
          if (element.name == service.name) {
            this.selectedServiceDetailsArray.splice(index, 1);
          }
        });
      }
    }
  }

  goNext() {
    window.scroll(0, 0);
    this.token = localStorage.getItem('session_token');
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.isPaymentSelected = false;
    this.isStepCompleted = true;
    // this.selectedServiceDetails = this.appService.getSelectedServiceDescriptionList();
    let serviceSelectedFromService = this.appService.getSelectedServiceDescriptionList();
    if (serviceSelectedFromService != undefined) {
      this.selectedServiceDetailsArray.push(serviceSelectedFromService);
      for (let i = 0; i < this.selectedServiceDetailsArray.length; i++) {
        this.selectedServiceDetailsArray[i].quantity = 1;
      }
      this.calculateTotalPriceForPayAsYouGo();
    } else if (
      this.selectedMembership == 'Pay as you go' &&
      serviceSelectedFromService == undefined
    ) {
      this.isAddServiceSelected = true;
      this.selectFromService = 'cloudComputing';
      this.servicesToBeSelectedFromArray = this.appService.getServiceListToDisplay(
        this.selectFromService
      );
      for (let i = 0; i < this.servicesToBeSelectedFromArray.length; +i++) {
        this.servicesToBeSelectedFromArray[i]['isChecked'] = false;
      }
    }
  }

  calculateTotalPriceForPayAsYouGo() {
    this.totalPriceForPayAsYouGo = 0;
    this.payAsYouGoSubTotal = 0;
    for (let i = 0; i < this.selectedServiceDetailsArray.length; i++) {
      this.payAsYouGoSubTotal +=
        parseFloat(this.selectedServiceDetailsArray[i].price) *
        this.selectedServiceDetailsArray[i].quantity;
    }
    this.taxes = 0.18 * this.payAsYouGoSubTotal;
    this.taxes = parseFloat(this.taxes).toFixed(2);
    this.totalPriceForPayAsYouGo =
      this.payAsYouGoSubTotal + parseFloat(this.taxes);
  }

  goBackFromAddService() {
    this.isAddServiceSelected = false;
    let serviceSelectedFromService = this.appService.getSelectedServiceDescriptionList();
    if (
      this.selectedMembership == 'Pay as you go' &&
      serviceSelectedFromService == undefined &&
      this.initialSelectedServiceForAddService.length == 0
    ) {
      this.isPaymentSelected = true;
      this.selectedServiceDetailsArray = JSON.parse(
        JSON.stringify(this.initialSelectedServiceForAddService)
      );
      this.isAddServiceSelected = false;
      this.membershipObject = {};
      this.itemsSelectedToCheckout = [];
      for (let i = 0; i < this.monthlyPaymentModeGridData.length; i++) {
        this.monthlyPaymentModeGridData[i].checked = false;
      }
    } else {
      if (
        this.initialSelectedServiceForAddService.length !=
        this.selectedServiceDetailsArray.length
      ) {
        this.selectedServiceDetailsArray = JSON.parse(
          JSON.stringify(this.initialSelectedServiceForAddService)
        );
      }
      this.isPaymentSelected = false;
    }
  }

  alterQuantity(action: any) {
    if (action == 'increase') {
      this.serviceQuantity++;
    } else if (action == 'decrease' && this.serviceQuantity > 1) {
      this.serviceQuantity--;
    }
    this.taxes = 0.18 * (this.membershipObject.price * this.serviceQuantity);
    this.taxes = parseFloat(this.taxes).toFixed(2);
    this.totalPrice =
      this.membershipObject.price * this.serviceQuantity +
      parseFloat(this.taxes);
  }

  alterPayAsYouGoQuantity(action: any, serviceobj: any) {
    if (action == 'increase') {
      serviceobj.quantity++;
    } else if (action == 'decrease' && serviceobj.quantity > 1) {
      serviceobj.quantity--;
    }
    this.calculateTotalPriceForPayAsYouGo();
  }

}
