import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppServiceService } from './../app-service.service';

@Component({
  selector: 'app-service-list-display',
  templateUrl: './service-list-display.component.html',
  styleUrls: ['./service-list-display.component.css']
})
export class ServiceListDisplayComponent implements OnInit {
  serviceTypeSelected: any;
  listOfServices: any;
  constructor(
    location: Location,
    private route: ActivatedRoute,
    private appService: AppServiceService
  ) {
    this.serviceTypeSelected = location.path().split('/')[2];
  }

  getServiceList(): void {
    this.listOfServices = this.appService.getListToDisplay(
      this.serviceTypeSelected
    );
  }
  ngOnInit() {
    this.getServiceList();
  }
}
