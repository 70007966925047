import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-component',
  templateUrl: './faq-component.component.html',
  styleUrls: ['./faq-component.component.css']
})
export class FaqComponentComponent implements OnInit {
  constructor() {}

  frequentlyAskedQUestions: any = [
    {
      question: 'Do you offer annual billing?',
      answer:
        'Yes, and we offer a discount for up-front annual billing. Please contact us for details.'
    },
    {
      question: 'How are Monthly Plan Hrs billed?',
      answer:
        'Tasks are billed in half-hour increments with a half-hour minimum. For example, a task that takes us 15 minutes to complete would be billed as ½ hour'
    },
    {
      question: 'Do you accept credit cards outside of the India?',
      answer:
        'Yes. our paypal payment processor accepts all major international credit cards.'
    },
    {
      question: 'Do you accept clients outside of the India?',
      answer: 'Absolutely. We would love to work with you!'
    },
    {
      question: 'What happens if I go over my plans included hours?',
      answer:
        "After you've utilized your plans included hours, you will be billed at your plans billable hourly rate above."
    }
  ];
  ngOnInit() {}
}
