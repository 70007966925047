import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-home-contact-us',
  templateUrl: './home-contact-us.component.html',
  styleUrls: ['./home-contact-us.component.css']
})
export class HomeContactUsComponent implements OnInit {
  @ViewChild('closeBtn', { static: true }) closeBtn: ElementRef;
  @ViewChild('thankYouButton', { static: false }) thankYouButton: ElementRef;
  fullName: any = '';
  emailAddress: any;
  mobileNumber: any;
  message: any = '';
  serviceSelected: any = '';
  apiURL = environment.apiUrl;
  token: any;
  isLoading = false;
  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    window.scroll(0, 0);
    this.token = localStorage.getItem('session_token');
  }
  onClick() {
    let fd = new FormData();
    fd.append('tokken', this.token);
    fd.append('name', this.fullName);
    fd.append('email', this.emailAddress);
    fd.append('mobile', this.mobileNumber);
    fd.append('service', this.serviceSelected);
    fd.append('message', this.message);
    this.isLoading = true;
    this.httpClient.post<any>(this.apiURL + 'contact_us', fd).subscribe(
      data => {
        if (data.status == true) {
          this.fullName = '';
          this.emailAddress = '';
          this.mobileNumber = '';
          this.message = '';
        } else {
          this.fullName = '';
          this.emailAddress = '';
          this.mobileNumber = '';
          this.message = '';
        }
        this.isLoading = false;
        this.thankYouButton.nativeElement.click();
      },
      error => {
        console.log('Error', error);
        this.isLoading = false;
      }
    );
  }
  selectService(serviceSelected) {
    this.serviceSelected = serviceSelected;
  }

  closeThePopup() {
    this.closeBtn.nativeElement.click();
  }
}
