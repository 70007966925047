import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infrastructure-service-of-home-page',
  templateUrl: './infrastructure-service-of-home-page.component.html',
  styleUrls: ['./infrastructure-service-of-home-page.component.css']
})
export class InfrastructureServiceOfHomePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  infrastructureServices : any[] = [
    {title:'Hyper converged & Converged Infrastructure' ,url:'/service/infrastructure/hyperCoveraged',src:'./../../assets/images/servies/004-network.png'},
    {title: 'Storage consulting',url:'/service/infrastructure/storageConsulting',src:'./../../assets/images/servies/033-network.png'},
    {title: 'Network consulting',url:'/service/infrastructure/networkConsulting',src:'./../../assets/images/servies/042-data.png'},
    {title: 'Virtualization consulting',url:'/service/infrastructure/virtualizationConsulting',src:'./../../assets/images/servies/005-touch.png'},
    {title: 'Data protection services',url:'/service/infrastructure/dataProtectionServices',src:'./../../assets/images/servies/025-private.png'}
  ]
}
