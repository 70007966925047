import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AppServiceService } from "./../app-service.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.css"]
})
export class MyAccountComponent implements OnInit {
  bookingTab: any = false;
  @ViewChild("closeBtn", { static: true }) closeBtn: ElementRef;
  @ViewChild("closeOnboardingBtn", { static: true })
  closeOnboardingBtn: ElementRef;
  @ViewChild("thankyouaccountcloseBtn", { static: true })
  thankyouaccountcloseBtn: ElementRef;
  @ViewChild("bookingOnboardingModal", { static: false })
  bookingOnboardingModal: ElementRef;
  @ViewChild("thankyoumodalbutton", { static: false })
  thankyoumodalbutton: ElementRef;
  userDetails: any;
  token: any;
  srcOfPP: any = "";
  initialsOfName: any = "";
  isUnscheduledSelected: any = true;
  apiURL = environment.apiUrl;
  scheduleTheService: boolean = false;
  scheduleServiceObj: any = {};
  selectedServiceDropdownValue: any = "none";
  serviceSelectedForschedulingObj: any = {};
  bsInlineValue = new Date();
  minDate = new Date();
  srcOfPPPath: any = "";
  ssObj: any = { name: "", selectedDate: "", selectedTimeslots: [] };
  bookedSlotsForSS: any = [];
  profilePicture: any = "Choose File";
  countries: any = this.appService.getCountries();
  timeslots: any = [
    { hour: "0.00- 1.00", isChecked: false, isBooked: false },
    { hour: "1.00- 2.00", isChecked: false, isBooked: false },
    { hour: "2.00- 3.00", isChecked: false, isBooked: false },
    { hour: "3.00- 4.00", isChecked: false, isBooked: false },
    { hour: "4.00- 5.00", isChecked: false, isBooked: false },
    { hour: "5.00- 6.00", isChecked: false, isBooked: false },
    { hour: "6.00- 7.00", isChecked: false, isBooked: false },
    { hour: "7.00- 8.00", isChecked: false, isBooked: false },
    { hour: "8.00- 9.00", isChecked: false, isBooked: false },
    { hour: "9.00- 10.00", isChecked: false, isBooked: false },
    { hour: "10.00- 11.00", isChecked: false, isBooked: false },
    { hour: "11.00- 12.00", isChecked: false, isBooked: false },
    { hour: "12.00- 13.00", isChecked: false, isBooked: false },
    { hour: "13.00- 14.00", isChecked: false, isBooked: false },
    { hour: "14.00- 15.00", isChecked: false, isBooked: false },
    { hour: "15.00- 16.00", isChecked: false, isBooked: false },
    { hour: "16.00- 17.00", isChecked: false, isBooked: false },
    { hour: "17.00- 18.00", isChecked: false, isBooked: false },
    { hour: "18.00- 19.00", isChecked: false, isBooked: false },
    { hour: "19.00- 20.00", isChecked: false, isBooked: false },
    { hour: "20.00- 21.00", isChecked: false, isBooked: false },
    { hour: "21.00- 22.00", isChecked: false, isBooked: false },
    { hour: "22.00- 23.00", isChecked: false, isBooked: false },
    { hour: "22.00- 23.00", isChecked: false, isBooked: false },
    { hour: "23.00- 0.00", isChecked: false, isBooked: false }
  ];
  selectedDate: any;
  unscheduledServiceList: any[] = [];
  demoImgUrl: any = "./../../assets/images/onboarding/onboarding_first.png";
  scheduledServiceList: any[] = [];
  imagePath: any;
  imgIndex: any = 0;
  imgURL: string | ArrayBuffer;
  modalCalledFrom: any;
  bookingOnboarding: any = [
    "./../../assets/images/onboarding/Bookiing onboarding.png",
    "./../../assets/images/onboarding/Booking onboarding 2.png"
  ];
  scheduleServiceOnBoarding: any = [
    "./../../assets/images/onboarding/scheduling onboarding.png",
    "./../../assets/images/onboarding/scheduling onboarding 2.png",
    "./../../assets/images/onboarding/scheduling onboarding 3.png"
  ];
  constructor(
    private appService: AppServiceService,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  showNextSlide() {
    if (this.modalCalledFrom == "booking") {
      if (this.imgIndex == this.bookingOnboarding.length - 1) {
        this.imgIndex = 0;
        this.closeOnboardingBtn.nativeElement.click();
      } else {
        this.imgIndex = this.imgIndex + 1;
      }
      this.demoImgUrl = this.bookingOnboarding[this.imgIndex];
    } else if (this.modalCalledFrom == "scheduleService") {
      if (this.imgIndex == this.scheduleServiceOnBoarding.length - 1) {
        this.imgIndex = 0;
        this.closeOnboardingBtn.nativeElement.click();
      } else {
        this.imgIndex = this.imgIndex + 1;
      }
      this.demoImgUrl = this.scheduleServiceOnBoarding[this.imgIndex];
    }
  }

  fileUploaded(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = _event => {
      this.imgURL = reader.result;
    };
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.token = localStorage.getItem("session_token");
    if (this.token == undefined || this.token == "" || this.token == null) {
      this.router.navigateByUrl("/");
    } else {
      this.appService.userDetails = JSON.parse(
        localStorage.getItem("user_details")
      );
      this.userDetails = this.appService.userDetails.userdata;
      if (this.userDetails.country == null || this.userDetails.country == "") {
        this.userDetails.country = "";
      }

      for (let i = 0; i < this.userDetails.name.split(" ").length; i++) {
        this.initialsOfName += this.userDetails.name.split(" ")[i].charAt(0);
      }
    }
  }

  signOut(event) {
    if (event.target.checked) {
      let fd = new FormData();
      fd.append("tokken", this.token);

      this.httpClient.post<any>(this.apiURL + "logout", fd).subscribe(
        data => {
          if (data.status == true) {
            localStorage.clear();
            this.appService.isUserLoggedIn = false;
            this.router.navigateByUrl("/");
          } else {
            console.log("Error", data);
          }
        },
        error => {
          console.log("Error", error);
        }
      );
    }
  }

  updateProfilePicture() {
    this.srcOfPP = this.imgURL;
    this.closeBtn.nativeElement.click();
  }
  updateAccountDetails() {
    this.appService.userDetails.userdata = this.userDetails;
    localStorage.setItem(
      "user_details",
      JSON.stringify(this.appService.userDetails)
    );
    for (let i = 0; i < this.userDetails.name.split(" ").length; i++) {
      this.initialsOfName += this.userDetails.name[i][0];
    }
    let fd = new FormData();
    fd.append("tokken", this.token);
    fd.append("user_type", this.appService.userDetails.userdata.user_type);
    fd.append("userid", this.userDetails.userid);
    fd.append("name", this.userDetails.name);
    fd.append("email", this.userDetails.email);
    fd.append("country", this.userDetails.country);
    fd.append("phone", this.userDetails.phone);
    fd.append("new_password", this.userDetails.newpassword);
    fd.append("userid", this.userDetails.userid);

    this.httpClient.post<any>(this.apiURL + "update_profile", fd).subscribe(
      data => {
        if (data.status == true) {
          console.log("profile updated");
        } else {
          console.log("Error", data);
        }
      },
      error => {
        console.log("Error", error);
      }
    );
  }

  onScheduleButtonClick(isUnscheduleSelected) {
    this.isUnscheduledSelected = isUnscheduleSelected;
  }
  clickedTab(selectedTab: any) {
    if (selectedTab === "booking") {
      this.bookingTab = true;
      var fd = new FormData();
      fd.append("tokken", this.token);
      fd.append("user_id", this.userDetails.userid);

      this.httpClient.post<any>(this.apiURL + "getBookings", fd).subscribe(
        data => {
          if (data.status == true) {
            var bookings = data.bookings;
            for (let i = 0; i < bookings.length; i++) {
              if (bookings[i].plan_type == "0") {
                if (
                  bookings[i].is_scheduled &&
                  bookings[i].is_scheduled == "0"
                ) {
                  var planName =
                    bookings[i].plan_id == "0"
                      ? "Silver Monthly Membership"
                      : bookings[i].plan_id == "1"
                      ? "Gold Monthly Membership"
                      : bookings[i].plan_id == "2"
                      ? "Platinum Monthly Membership"
                      : "";
                  this.unscheduledServiceList.push({
                    bookingId: bookings[i].id,
                    name: planName,
                    servicesSelected: [
                      {
                        name:
                          "All Cloud Computing and infrastructure services are included in this membership",
                        hours: "12 Hrs"
                      }
                    ]
                  });
                }
              } else if (bookings[i].plan_type == "1") {
                var unscheduledlist = [];
                var scheduledlist = [];
                for (let j = 0; j < bookings[i].booked_services.length; j++) {
                  var payAsYouGoBookedService = {
                    service_id: bookings[i].booked_services[j].id,
                    name: bookings[i].booked_services[j].service_name,
                    hour: bookings[i].booked_services[j].no_of_hour,
                    is_scheduled: bookings[i].booked_services[j].is_scheduled
                  };
                  if (payAsYouGoBookedService.is_scheduled == "0") {
                    unscheduledlist.push(payAsYouGoBookedService);
                  } else {
                    scheduledlist.push(payAsYouGoBookedService);
                  }
                }
                if (unscheduledlist.length > 0) {
                  this.unscheduledServiceList.push({
                    bookingId: bookings[i].id,
                    name: "Pay as you go",
                    servicesSelected: unscheduledlist
                  });
                }
                if (scheduledlist.length > 0) {
                  this.scheduledServiceList.push({
                    bookingId: bookings[i].id,
                    name: "Pay as you go",
                    servicesSelected: scheduledlist,
                    selectedDate: formatDate(
                      bookings[i].booking_date,
                      "dd/MMM/yyyy",
                      "en-US",
                      "GMT+0530 (India Standard Time)"
                    )
                  });
                }
              }
            }
          } else {
            console.log("Error", data);
          }
          this.demoImgUrl = this.bookingOnboarding[0];
          this.modalCalledFrom = "booking";
          this.bookingOnboardingModal.nativeElement.click();
        },
        error => {
          console.log("Error", error);
        }
      );
    } else {
      this.bookingTab = false;
    }
  }

  goToPaymentPlan() {
    this.router.navigateByUrl("/paymentplan");
  }
  scheduleService(service) {
    var fd = new FormData();
    fd.append(
      "date",
      formatDate(
        new Date(),
        "yyyy-MM-dd",
        "en-US",
        "GMT+0530 (India Standard Time)"
      )
    );

    this.httpClient.post<any>(this.apiURL + "getBookedSlot", fd).subscribe(
      data => {

        if (data.status == true) {
          this.scheduleTheService = true;
          this.scheduleServiceObj = service;
          for (let i = 0; i < data.booked_slots.length; i++) {
            this.timeslots[data.booked_slots[i]].isBooked = true;
          }
          // if(!isUnscheduleSelected){
          this.modalCalledFrom = "scheduleService";
          this.demoImgUrl = this.scheduleServiceOnBoarding[0];
          this.bookingOnboardingModal.nativeElement.click();
          // }
        } else {
          console.log("Error", data);
        }
      },
      error => {
        console.log("Error", error);
      }
    );
  }

  cancelSchedulingService() {
    this.scheduleTheService = false;
    window.location.reload();
  }

  selectedServiceForScheduling(serviceSelectedForschedulingObj) {
    this.serviceSelectedForschedulingObj = serviceSelectedForschedulingObj;
  }
  selectedServiceForSchedulingTimeSlot(event) {
    this.ssObj.name = event.target.value;
  }

  selectTimeSlot(event, selectedHour) {
    if (event.target.checked) {
      this.ssObj.selectedTimeslots.push(selectedHour);
    } else {
      for (let i = 0; i < this.ssObj.selectedTimeslots.length; i++) {
        if (this.ssObj.selectedTimeslots[i] == selectedHour) {
          this.ssObj.selectedTimeslots.splice(i, 1);
        }
      }
    }
  }

  updateSSSelectedData() {
    if (
      (this.ssObj.index == undefined || this.ssObj.index == null) &&
      this.ssObj.name != "" &&
      this.ssObj.selectedTimeslots.length != 0
    ) {
      this.ssObj.index = this.bookedSlotsForSS.length;
      this.bookedSlotsForSS.push(this.ssObj);
    } else {
      for (let j = 0; j < this.bookedSlotsForSS.length; j++) {
        if (this.bookedSlotsForSS[j].index == this.ssObj.index) {
          this.bookedSlotsForSS[j] = this.ssObj;
        }
      }
    }
    for (let k = 0; k < this.timeslots.length; k++) {
      this.timeslots[k].isChecked = false;
    }
    this.selectedServiceDropdownValue = "none";
    var today = formatDate(
      new Date(),
      "dd/MMM/yyyy",
      "en-US",
      "GMT+0530 (India Standard Time)"
    );
    this.bsInlineValue = new Date();
    this.ssObj = { name: "", selectedDate: today, selectedTimeslots: [] };
  }
  onValueChangeOfDatePicker(value: Date) {
    this.ssObj.selectedDate = formatDate(
      value,
      "dd/MMM/yyyy",
      "en-US",
      "GMT+0530 (India Standard Time)"
    );
    var fd = new FormData();
    fd.append(
      "date",
      formatDate(
        this.ssObj.selectedDate,
        "yyyy-MM-dd",
        "en-US",
        "GMT+0530 (India Standard Time)"
      )
    );

    this.httpClient.post<any>(this.apiURL + "getBookedSlot", fd).subscribe(
      data => {

        if (data.status == true) {
          for (let i = 0; i < this.timeslots.length; i++) {
            this.timeslots[i].isBooked = false;
          }
          for (let i = 0; i < data.booked_slots.length; i++) {
            this.timeslots[data.booked_slots[i]].isBooked = true;
          }
          for (let i = 0; i < this.timeslots.length; i++) {
            this.timeslots[i].isChecked = false;
          }
          for (let j = 0; j < this.bookedSlotsForSS.length; j++) {
            if (
              formatDate(
                value,
                "dd/MMM/yyyy",
                "en-US",
                "GMT+0530 (India Standard Time)"
              ) == this.bookedSlotsForSS[j].selectedDate
            ) {
              for (
                let k = 0;
                k < this.bookedSlotsForSS[j].selectedTimeslots.length;
                k++
              ) {
                var index = this.bookedSlotsForSS[j].selectedTimeslots[
                  k
                ].hour.charAt(0);
                this.timeslots[index].isBooked = true;
              }
            }
          }
        } else {
          console.log("Error", data);
        }
      },
      error => {
        console.log("Error", error);
      }
    );
  }

  onClickOfBookSlotButtonSS() {
    var servicesSS = [];
    for (let i = 0; i < this.bookedSlotsForSS.length; i++) {
      for (
        let j = 0;
        j < this.scheduleServiceObj.servicesSelected.length;
        j++
      ) {
        if (
          this.bookedSlotsForSS[i].name ==
          this.scheduleServiceObj.servicesSelected[j].name
        ) {
          this.bookedSlotsForSS[
            i
          ].service_id = this.scheduleServiceObj.servicesSelected[j].service_id;
        }
      }
      var slots = [];
      for (
        let k = 0;
        k < this.bookedSlotsForSS[i].selectedTimeslots.length;
        k++
      ) {
        slots.push(
          this.bookedSlotsForSS[i].selectedTimeslots[k].hour.charAt(0)
        );
      }
      var obj = {
        service_id: this.bookedSlotsForSS[i].service_id,
        service_name: this.bookedSlotsForSS[i].name,
        slot: slots,
        date: formatDate(
          this.bookedSlotsForSS[i].selectedDate,
          "yyyy-MM-dd",
          "en-US",
          "GMT+0530 (India Standard Time)"
        )
      };
      servicesSS.push(obj);
    }
    var requestObj = {
      tokken: this.token,
      user_id: this.userDetails.userid,
      booking_id: this.scheduleServiceObj.bookingId,
      plan_type: 1,
      services: servicesSS
    };

    this.httpClient
      .post<any>(this.apiURL + "scheduleService", requestObj)
      .subscribe(
        data => {
          if (data.status == true) {
            this.scheduledServiceList = data.scheduledServiceList;
            this.thankyoumodalbutton.nativeElement.click();
          } else {
            console.log("Error", data);
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  closeTheContactPopup() {
    this.cancelSchedulingService();
    this.thankyouaccountcloseBtn.nativeElement.click();
  }

  removeTheSelection(ObjToRemove) {
    for (let i = 0; i < ObjToRemove.selectedTimeslots.length; i++) {
      this.timeslots[
        ObjToRemove.selectedTimeslots[i].hour.charAt(0)
      ].isBooked = false;
    }
    this.bookedSlotsForSS.splice(ObjToRemove.index, 1);
    for (let i = 0; i < this.bookedSlotsForSS.length; i++) {
      this.bookedSlotsForSS[i].index = i;
    }
  }
}
