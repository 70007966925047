import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {
  constructor() {}
  carouselContent: any = [
    {
      name: 'Transforming lives through innovations',
      description:
        'We help organizations become <span id="cloudComputingPara">Agile, Innovative and smart</span>',
      img: '../../assets/images/slide1.png',
      href: '#contact',
      knowMoreText: '2hrs of free consulting'
    },
    {
      name: 'Transforming Ideas into solutions',
      description:
        'Driving Efficiency and Growth with Advanced AI-powered Applications',
      img: '../../assets/images/slide2.png',
      href: 'service/digitalService',
      knowMoreText: 'Know More'
    },
    {
      name: 'Delivering smart solutions for smart businesses',
      description:
        'Achieve a higher level of digital transformation through our innovative solutions.',
      img: '../../assets/images/slide3.png',
      href: 'service/cloudComputing',
      knowMoreText: 'Know More'
    }

    // ,{
    //   name: 'We love playing with technology that solves real world problems',
    //   description:
    //     'Join iSAN DATA SYSTEMS and try 2 hrs of our services for free. ',
    //   img: '../../assets/images/slide4.png',
    //   href: '#contact',
    //   knowMoreText: 'Contact Us'
    // }
  ];

  carouselOptions = {
    margin: 0,
    nav: false,
    loop: true,
    slideBy: 1,
    autoplay: true,
    dotsEach: false,
    center: true,
    // stagePadding: 40,
    // navText: ["<div class='nav-btn  prev-slide'></div>", "<div class='nav-btn  next-slide'></div>"],
    responsiveClass: true,
    items: 1,
    navSpeed: 3000,
    width: 100
  };
  ngOnInit() {}
}
