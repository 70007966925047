import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-service',
  templateUrl: './application-service.component.html',
  styleUrls: ['./application-service.component.css']
})
export class ApplicationServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  applicationServices : any[] = [
    {title:'Website design & application',url:'/service/application/websiteDesignAndApplication',src:'./../../assets/images/servies/006-database.png'},
    {title:'Mobile Application',url:'/service/application/mobileApplication',src:'./../../assets/images/servies/021-mobile.png'},
    {title: 'UI / UX Design',url:'/service/application/UIUXDesign',src:'./../../assets/images/servies/027-touch-screen.png'},
    {title: 'E - Commerce solutions',url:'/service/application/eCommerceSolutions',src:'./../../assets/images/servies/013-big data.png'}
  ]
}
