import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-service',
  templateUrl: './digital-service.component.html',
  styleUrls: ['./digital-service.component.css']
})
export class DigitalServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  digitalServices : any[] = [
    {title:'Artificial Intelligence',url:'/service/digitalService/artificialIntelligence',src:'./../../assets/images/servies/025-artificial intelligence.png'},
    {title:'Machine learning',url:'/service/digitalService/machineLearning',src:'./../../assets/images/servies/035-artificial intelligence.png'},
    {title:'Internet of things',url:'/service/digitalService/iot',src:'./../../assets/images/servies/037-share.png'},
    {title:'Digital marketing',url:'/service/digitalService/digitalMarketing',src:'./../../assets/images/servies/017-pie chart.png'}
  ]
}
