import {
  Component,
  OnInit,
  HostListener,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { logging } from 'protractor';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { AppServiceService } from './../app-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  e;
  @ViewChild('closeBtn', { static: true }) closeBtn: ElementRef;
  loginType: any = 'Login';
  isUserLoggedIn: any = false;
  isLoading: any = false;
  recoveryEmailAddress: any = '';
  isForgetPassword: any = false;
  user: any;
  isEmailRecovered: any = false;
  invalidEmailMessage: any = '';
  errorMessage: any = '';
  userLoggedinSuccessfully: any = false;
  signup: any = {
    name: '',
    email: '',
    password: ''
  };
  login: any = {
    email: '',
    password: ''
  };
  apiURL = environment.apiUrl;

  setCalledFromVar() {
    this.appService.loginModalCalledFrom = 'header';
  }

  forgetPasswordClicked(value) {
    this.isForgetPassword = value;
  }
  recoverEmail(recoveryEmailAddress) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(recoveryEmailAddress)) {
      let fd = new FormData();
      fd.append('email', recoveryEmailAddress);
      this.httpClient.post<any>(this.apiURL + 'forget_password', fd).subscribe(
        data => {
          if (data.status == true) {
            this.isEmailRecovered = true;
            this.invalidEmailMessage = '';
          } else {
            this.invalidEmailMessage =
              'The email address you have entered is not valid, please enter valid email address.';
          }
        },
        error => {
          console.log('Error', error);
          this.errorMessage = error.message;
        }
      );
    } else {
      this.invalidEmailMessage = 'please enter correct email';
    }
  }

  createUserAccount(loginTypeObject: any) {
    if (this.loginType == 'Login') {
      loginTypeObject.login_type = 'normal';
      let fd = new FormData();
      fd.append('email', this.login.email);
      fd.append('password', this.login.password);
      this.isLoading = true;
      this.httpClient.post<any>(this.apiURL + 'login', fd).subscribe(
        data => {
          if (data.status == true) {
            this.appService.userDetails = data;
            this.appService.isUserLoggedIn = true;
            this.errorMessage = '';
            localStorage.setItem('session_token', data.userdata.tokken);
            localStorage.setItem('user_details', JSON.stringify(data));
            this.closeBtn.nativeElement.click();
            if (this.appService.loginModalCalledFrom != 'payment') {
              this.router.navigateByUrl('/myaccount');
            }
          } else {
            this.errorMessage = data.message;
          }
          this.isLoading = false;
        },
        error => {
          console.log('Error', error);
          this.isLoading = false;
          this.errorMessage = error.message;
        }
      );
    } else if (this.loginType == 'SignUp') {
      let fd = new FormData();
      fd.append('name', this.signup.name);
      fd.append('email', this.signup.email);
      fd.append('password', this.signup.password);
      this.isLoading = true;
      this.httpClient.post<any>(this.apiURL + 'signup', fd).subscribe(
        data => {
          if (data.status == true) {
            this.appService.userDetails = data;
            this.appService.isUserLoggedIn = true;
            this.errorMessage = '';
            localStorage.setItem('session_token', data.userdata.tokken);
            localStorage.setItem('user_details', JSON.stringify(data));
            this.closeBtn.nativeElement.click();
            if (this.appService.loginModalCalledFrom != 'payment') {
              this.router.navigateByUrl('/myaccount');
            }
          } else {
            this.errorMessage = data.message;
          }
          this.isLoading = false;
        },
        error => {
          console.log('Error', error);
          this.isLoading = false;
          this.errorMessage = error.message;
        }
      );
    }
  }

  goToMyAccounts() {
    this.router.navigateByUrl('/myaccount');
  }

  constructor(
    private httpClient: HttpClient,
    public appService: AppServiceService,
    private router: Router
  ) {}

  ngOnInit() {
    var token = localStorage.getItem('session_token');
    this.appService.userDetails = JSON.parse(
      localStorage.getItem('user_details')
    );
  }

  toggleLoginType(event, switchSelected) {
    this.loginType = switchSelected;
  }
}
