import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  serviceSelected: any = 'cloudComputing';
  serviceTypeList: any[] = [
    { name: 'Cloud Computing', serviceType: 'cloudComputing' },
    { name: 'Infrastructure', serviceType: 'infrastructure' },
    { name: 'Application', serviceType: 'application' },
    { name: 'Digital Service', serviceType: 'digitalService' }
  ];

  whyServiceObj: any = {
    cloudComputing: {
      header: 'Why Cloud Computing?',
      description:
        'Regardless of which cloud computing service you use, one thing is for sure- large quantities of data will move to and fro between the data centers of the cloud providers and your end-users.<br><br>Through our cloud computing services, we can help you in taking the next leap toward strategizing and optimizing your business operations, application development, and information access.',
      imgsrc: './../../assets/images/servies/999 [Converted].png'
    },
    infrastructure: {
      header: 'Infrastructure Services',
      description:
        'At ISAN Data Systems, we assist enterprises with the deployment of Cloud within their journey of digital transformation to meet their business goals and boost growth. Through our risk-mitigated cloud strategies that are specially tailored for your business requirements, we recommend you all possible deployment models, with least downtime.<br><br> Thus, we assist companies with our expert consultancy in ensuring a seamless, agile, and secure transition from their in-house IT operations to a cloud infrastructure.',
      imgsrc: './../../assets/images/servies/infra.png'
    },
    application: {
      header: 'Application Design',
      description:
        'Speed up your digital transformation by streamlining, updating, and securing your applications environment.<br><br> Shift your IT expenditure from legacy to leading edge. Modern applications are all about delivering the experience customers want and the results that you want to see. ISAN Data Systems can be your perfect partner in your organization’s acceleration to a digitized, secure, and data-oriented enterprise.',
      imgsrc: './../../assets/images/servies/undraw_marketing_v0iu.png'
    },
    digitalService: {
      header: 'Digital Services',
      description:
        'The storm of wide array of services like Machine learning, Internet of things, Artificial Intelligence are playing a vital role in revolutionizing the companies.They can help make products and services smarter, therefore, more valuable. These technologies can also help support human workers and decision makers, in general, improve business operations.',
      imgsrc: './../../assets/images/servies/digitalservice.png'
    }
  };

  advantages: any[] = [
    {
      name: 'Safe & Secure',
      image: '/safe and secure.png'
    },
    {
      name: 'Reliability',
      image: '/Reliability.png'
    },
    {
      name: 'Low Cost',
      image: '/Low cost.png'
    },
    {
      name: 'Scalable',
      image: '/scalability.png'
    }
  ];

  adoptionProcess: any = {
    cloudComputing: [
      {
        srno: '01',
        name: 'Research And Strategy',
        description:
          'Understand the business value,IT feasibility and success factors for your organization to deploy your applications in cloud.'
      },
      {
        srno: '02',
        name: 'Assessment & Planning',
        description:
          'Assess current state and quantify the benefits and efforts to move to cloud. Identify key requirements, concerns, controls and constrains for cloud plan.'
      },
      {
        srno: '03',
        name: 'Adoption & Migration',
        description:
          'In this phase, develop application migration strategies, identify the servers, the data stores and software to realize migration road maps.'
      },
      {
        srno: '04',
        name: 'Evolution & Transition',
        description:
          'At this stage, cloud service management is crucial to extract the true benefits of the cloud and make cloud operations manageable.'
      }
    ],
    infrastructure: [
      {
        srno: '01',
        name: 'Comprehensive capabilities, flexible engagement',
        description: ''
      },
      {
        srno: '02',
        name: 'Transformation without mind bending complexity',
        description: ''
      },
      { srno: '03', name: 'Industrialized approach', description: '' },
      {
        srno: '04',
        name: 'Proven results & Sector specific Expertise',
        description: ''
      }
    ]
  };
  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (location.pathname.includes('cloudComputing')) {
        this.serviceSelected = 'cloudComputing';
      } else {
        this.serviceSelected = location.pathname.split('/')[2];
      }
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
  }
  onSelectService(service) {
    this.serviceSelected = service;
  }
}
