import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {
  serviceDescriptionListSelected: any;
  userDetails: any = {};
  loginModalCalledFrom: any;
  isUserLoggedIn: any = false;
  selectedMembershipObj: any = {};
  serviceTypeList: any[] = [
    {
      cloudComputing: [
        { id: 1, name: 'Cloud Assessment & Strategy', url: 'cloudAssessmentStrategy' },
        { id: 2, name: 'Cloud Migration', url: 'cloudMigration' },
        { id: 3, name: 'Cloud Operations', url: 'cloudOperations' },
        { id: 4, name: 'Cloud security & governance', url: 'cloudSecurityGovernance' },
        { id: 5, name: 'Cloud FinOps', url: 'cloudFinOps' },
        { id: 6, name: 'DevOps Assessment', url: 'devOpsAssessment' },
        { id: 7, name: 'DevOps Automation', url: 'devOpsAutomation' },
        { id: 8, name: 'DevOps management', url: 'devOpsManagement' },
        { id: 9, name: 'Kubernetes Consulting', url: 'kubernetesConsulting' }

      ]
    },
    {
      infrastructure: [
        { id: 1, name: 'Hyper converged & Converged Infrastructure', url: 'hyperCoveraged' },
        { id: 3, name: 'Storage consulting', url: 'storageConsulting' },
        { id: 4, name: 'Network consulting', url: 'networkConsulting' },
        { id: 5, name: 'Virtualization consulting', url: 'virtualizationConsulting' },
        { id: 6, name: 'Data protection services', url: 'dataProtectionServices' }
      ]
    },
    {
      application: [
        { id: 1, name: 'Website design & application', url: 'websiteDesignAndApplication' },
        { id: 2, name: 'Mobile Application', url: 'mobileApplication' },
        { id: 3, name: 'UI / UX Design', url: 'UIUXDesign' },
        { id: 4, name: 'E - Commerce solutions', url: 'eCommerceSolutions' }
      ]
    },
    {
      digitalService: [
        { id: 1, name: 'Artificial Intelligence', url: 'artificialIntelligence' },
        { id: 2, name: 'Machine learning', url: 'machineLearning' },
        { id: 3, name: 'Internet of things', url: 'iot' },
        { id: 4, name: 'Digital marketing', url: 'digitalMarketing' }
      ]
    }
  ];
  serviceTypeContent: any[] = [
    {
      cloudComputing: {
        cloudAssessmentStrategy: {
          name: 'Cloud Assessment and Strategy',
          description: [
            {
              header: 'Fast tracking your Cloud Transformation',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Cloud assessment and strategy (1).png"></div><div class="col-sm-6">Our cloud assessment services will give you deep insights into the process of cloud migration and help you identify which workloads and enterprise applications are worth being moved to the cloud. We also inform you about the specific processes, operating models, and technologies that are needed to use cloud technology. Thus, we help you evaluate cloud as an integral aspect of your IT service delivery strategy.</div></div>'
            },
            {
              header: 'Your guide to the right cloud strategy.',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Cloud assessment and strategy (2).png"></div><div>Deep understanding of your business requirements aids us in providing you with a scalable and cost-effective methodology. We accelerate your cloud adoption strategy through various cloud assessment tools that can evaluate the capabilities and performance of many cloud services. After adequately assessing your business requirements and needs for cloud adoption, we create an optimal strategy and the road map for your cloud journey. Thus, we not only give you a clear picture of your current resources but also provide you with clarity about where your organization would be after the cloud adoption.</div>'
            }
          ],
          shortDescription: "Our cloud assessment services will give you deep insights into the process of cloud migration and help you identify which workloads and enterprise applications are worth being moved to the cloud.",
          price: 80.00
        },
        cloudMigration: {
          name: 'Cloud Migration',
          description: [
            {
              header: 'Almost half of the organizations that choose to migrate to the cloud exceed their budget overwhelmingly. How do you plan to keep your migration under check?',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Cloud Migration.png"></div><div>While every system migration has challenges of its own, the kind of challenges that Cloud Migration is replete which affects various parts of the organizational system. Some of the key challenges faced during Cloud Migration are-<br><ul><li>Specialized cloud skill requirements</li><li>Effect on existing operations.</li><li>Compliance and security-related issues</li><li>Complexities in cloud architecture</li></ul><br>Our cloud migration services can help you mitigate these complexities and move your workloads to loud with minimal impact to your operations. Through our end-to-end strategies, we help you migrate heterogeneous workloads from any source and leverage the advantages of cloud migration. With the help of our experts, we leverage our tools for cloud data migration to shift your health data at any scale and ensure a quick and smooth migration to the cloud.</div></div>'
            }
          ],
          shortDescription: "Through our end-to-end strategies, we help you migrate heterogeneous workloads from any source and leverage the advantages of cloud migration. Do you help with your cloud migration?",
          price: 80.00
        },
        cloudOperations: {
          name: 'Cloud Operations',
          description: [
            {
              header: 'Let’s unlock the true potential of Cloud',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Cloud operations (1).png"></div><div class="col-sm-6">Most organizations today are shifting their workloads to the cloud to reap the cost benefits that it brings along with the effective resource utilization. However, merely migrating to the cloud won’t unleash its real potential to transform your operations. It is equally imperative for you to manage the cloud environment after migration. Only then can you optimize cost savings, ensure efficient resource utilization, meet specific standards of operation quality, adhere to compliance requirements, and deliver overall customer satisfaction.</div></div>'
            },
            {
              header: 'Fueling agility in businesses through holistic partner ecosystem.',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Cloud operations (2).png"></div><div>At ISAN Data Systems, we offer complete management of your hybrid cloud environment, which would help you leverage cost efficiencies through shared services and the flexibility that commercial models offer. Our services for cloud operation are based on an ITIL based standard methodology to take care of cloud operations. By leveraging our cloud operations, you can manage a persistent cloud environment with optimized resource utilization.'
            }
          ],
          shortDescription: "At ISAN Data Systems, we offer complete management of your hybrid cloud environment, which would help you leverage cost efficiencies through shared services and the flexibility that commercial models offer. ",
          price: 80.00
        },
        cloudSecurityGovernance: {
          name: 'Cloud Security & Governance',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/cloud security and governance.png"></div><div class="col-sm-6">As organizations move more of their data and infrastructure to the cloud, cloud security governance has become an increasingly critical issue. System vulnerabilities, data breaches, and credential and access management are some of the main security challenges that organizations often have to address in a cloud environment. Cloud security governance is the key to ensuring that your information is safe and secure and your organization doesn’t become vulnerable.</div></div>'
            },
            {
              header: 'Flexible and Powerful Cloud Security Solutions for Optimized Governance Policies.',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Cloud security and governance(2).png"></div><div>Cloud security & governance can be explained in a nutshell as-<br><br><ul><li>Doing the right things</li><li>Doing things correctly.</li><li>Measuring the efficacy of the two steps mentioned above.</li></ul><br>We, at ISAN Data Systems, assist organizations in determining the best approach for the integration of new processes with the existing policies for IT governance, tools, and dashboards. With our next-generation framework for cloud security & governance, we assist organizations with features of IT governance that are unique to the elements of risk, benefits, value creation, and resource optimization of cloud computing. Thus, we help you make the most out of the speed and flexibility that the cloud provides, without compromising on the functionality.</div>'
            }
          ],
          shortDescription: "We, at ISAN Data Systems, assist organizations in determining the best approach for the integration of new processes with the existing policies for IT governance, tools, and dashboards. With our next- generation framework for cloud security & governance.",
          price: 80.00
        },
        cloudFinOps: {
          name: 'Cloud FinOps-( Cloud financial optimization)',
          description: [
            {
              header: 'Master the unit economics of cloud',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Cloud Finops.png"></div><div class="col-sm-6">As part of our FinOps services, we assist companies with complete process, application, security, and architecture and deliver a comprehensive organizational review when building a FinOp framework. With our unique approach to ensuring cloud governance through tool and policies based on best practices, we help businesses integrate cloud with legacy IT landscape in a FinOps model.</div></div>'
            },
            {
              header: 'Your business transformation begins right here',
              paragraph: 'Through a centralized governance platform, we provide visibility, security, optimization, and governance across public, private, and hybrid cloud platform models, including GCP, AWS, Azure. Our cloud audits provide sustained support and evaluation against all areas of cloud within the framework to deliver security and process & performance auditing.'
            }
          ],
          shortDescription: "As part of our FinOps services, we assist companies with complete process, application, security, and architecture and deliver a comprehensive organizational review when building a FinOp framework.",
          price: 80.00
        },
        devOpsAssessment: {
          name: 'DevOps Assessment',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Devops assessment.png"></div><div class="col-sm-6">DevOps allows organizations to integrate operations and development, expedite the release of the different software, and deploy them more efficiently. The growing significance of continuous product delivery has resulted in the rise of demand for DevOps services.<br>Our DevOps assessment services can transform the way your business has performed traditionally.</div></div>'
            },
            {
              header: 'Clinical DevOps Assessment to co-create the transformation road map',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Devops assessment (2).png"></div><div>At ISAN Data Systems, we assess the current state of your DevOps process, culture, and tool chain and analyze the key pain points. We then chart out the road map and recommend tools and processes that can automate the development cycles across your entire delivery infrastructure. As part of our DevOps assessment services, we assist you by-<br>' +
                '<ul><li>Assessing the current state of DevOps system in your organization</li><li>Quantifying your maturity model</li><li>Visualizing the desired state</li><li>Charting out the road map and action plan</li><li>Identifying traceable metrics</li><li>Accommodating organizational change</li></ul><br>Our deep expertise in DevOps can play a critical role in getting you started on the right path to sustained integration and delivery.</div>'
            }
          ],
          shortDescription: "DevOps allows organizations to integrate operations and development, expedite the release of the different software, and deploy them more efficiently. The growing significance of continuous product delivery has resulted in the rise of demand for DevOps services.",
          price: 90.00
        },
        devOpsAutomation: {
          name: 'DevOps Automation',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Devops automation.png"></div><div class="col-sm-6">DevOps automation has become a key driver of trending technological solutions as it helps in the elimination of recurring action items, allowing organizations to focus extensively on value addition. However, it is one thing to find the right technology and a completely different ball game to choose a partner that can assist you in the successful implementation of the chosen solutions and give you a competitive edge. But you need not worry.</div></div>'
            },
            {
              header: 'The key to Consistent Integration and Continuous Delivery.',
              paragraph: 'At ISAN Data Systems, we provide you with integrated solutions that can bring about an exponential increase in your pace of deployment. Our proven and extensive experience in dealing with all-around DevOps services places us ideally to take care of your automation requirements. We offer a range of DevOps automation services that can ease the automation process in a process chain driven by DevOps. Some of these services include-<br>' +
                '<ul><li>Testing and deployment.</li><li>Software releases.</li><li>Infrastructure provisioning</li><li>Core security tasks</li></ul><br>We automate end-to-end delivery cycle and perform your deployments and rollbacks in a click with mitigated risks and enhanced productivity.'
            }
          ],
          shortDescription: "DevOps automation has become a key driver of trending technological solutions as it helps in the elimination of recurring action items, allowing organizations to focus extensively on value addition. Our experience in DevOps services places us ideally to take care of your requirements.",
          price: 90.00
        },
        devOpsManagement: {
          name: 'DevOps Management',
          description: [
            {
              header: 'You focus on innovation, we will focus on the infrastructure.',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Devops Management (1).png"></div><div class="col-sm-6">DevOps has established a new culture of automating traditional operations and has broken down the conventional silos. DevOps management involves controlling the tools, applications, processes, infrastructure, and day-to-day operations to enhance automation and agility, consequently lessening the time-to-market.</div></div>'
            },
            {
              header: 'Accelerate your DevOps Journey with ISAN Data System',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Devops Management (2).png"></div><div>We, at ISAN Data Systems, have been actively engaged in providing our clients with DevOps management services. These services include offering automated frameworks and standardized processes to our clients for a wide range of activities, which include managing incidents, monitoring DevOps environment, and configuring infrastructure.</div>'
            },
            {
              header: 'We’ll focus on the infrastructure, you focus on innovation.',
              paragraph: 'We help you in ensuring a full-fledged integrated functioning of the delivery pipeline automation cycle and in adapting your automated development cycle to changes. With our highly-qualified team of DevOps experts, we evaluate your organization’s current DevOps maturity levels and suggest enhancements across various development and operations functions. We also analyze your business value and expedite the process of implementation of the DevOps framework.'
            }
          ],
          shortDescription: "DevOps management involves controlling the tools, applications, processes, infrastructure, and day-to-day operations to enhance automation and agility, consequently lessening the time-to-market. iSANDATASYSTEMS has been actively engaged in providing our clients with DevOps management services",
          price: 90.00
        },
        kubernetesConsulting: {
          name: 'Kubernetes Consulting',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/cloud computing/Kubernetes(1).png"></div><div class="col-sm-6">We have helped several organizations in streamlining their CD/CI process by providing them with excellent consultancy on Kubernetes. With our high expertise in offering optimum Kubernetes solutions to our clients, we can help you transform your container strategy with our expert advice.</div></div>'
            },
            {
              header: 'We help awesome companies stay miles ahead of the competition',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/cloud computing/Kubernetes.png"></div><div> At ISAN Data Systems, we can assist you with Kubernetes consulting, support, and licensing needs. We can help you introduce Kubernetes to your company by creating CI/CD pipelines to create, examine, package, deploy your applications.<br><br>Our experts can leverage their rich expertise in creating and deploying Kubernetes solutions to build and manage your Kubernetes clusters and work with your developers in tandem to assist them in their deployments. Our technical know-how can help you migrate your legacy application from running on VMs or other orchestration systems to run and scale on Kubernetes. Also, we can guide you through continuous integration and deployment of your cloud-native apps into Kubernetes.</div>'
            }
          ],
          shortDescription: "We have helped several organizations in streamlining their CD/CI process by providing them with excellent consultancy on Kubernetes. We can help you transform your container strategy with our expert advice.",
          price: 90.00
        }
      }
    },
    {
      infrastructure: {
        hyperCoveraged: {
          name: 'Hyper-Converged & Converged Infrastructure Consulting',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/converged and hyper converged (1).png"></div><div class="col-sm-6">we offer you the expert guidance that can help you deliver flexible data management and powerful data virtualization capabilities across the Hyper-Converged Infrastructure (HCI). Through a blend of our technical knowledge and the far-reaching potential of HCI, we can help you enhance your business resilience, availability, and efficiency.</div></div>'
            },
            {
              header: 'Delivering Hyperconvergence to the Conventional Data Centers.',
              paragraph: '<div class="row second-description-image"><img src="./../../assets/images/servies/converged and hyper converged (2).png"></div><div>We recommend HCI solutions that both easy to design and deploy. It allows even the new users to get started quickly with a single workload and then expand extensively and seamlessly. <br><br>Thus, we help you secure your data while expediting analytics, application development, and overall production.</div>'
            }
          ],
          shortDescription: "We offer you the expert guidance that can help you deliver flexible data management and powerful data virtualization capabilities across the Hyper-Converged Infrastructure (HCI).",
          price: 80.00
        },
        storageConsulting: {
          name: 'Storage Consulting',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/Storageconsulting (2).png"></div><div class="col-sm-6">We help companies solve complex IT infrastructure and data storage problems through our in-depth domain knowledge and the application of best industry practices. Through our consulting services that are entirely customer-centric, we leverage our extensive industry expertise to help organizations enhance their business responsiveness</div></div>'
            },
            {
              header: 'Eliminate complexity and modernize your storage infrastructure',
              paragraph: '<div class="row description-image"><img class="col-sm-8 mx-auto" src="./../../assets/images/servies/Storageconsulting (1).png"></div><div class="row">We help our esteemed clients like yourselves in accelerating their IT operations and reach top-notch levels with design, execution, and deployment of your cloud solutions. Through our consultancy services, we help you in streamlining your data storage needs with industry-leading solutions. Also, we help you secure your data assets while reducing storage requirements and network loads, thereby simplifying your data landscape</div>'
            }
          ],
          shortDescription: "We help companies solve complex IT infrastructure and data storage problems through our in-depth domain knowledge and the application of best industry practices through our consulting services that are entirely customer-centric.",
          price: 80.00
        },
        networkConsulting: {
          name: 'Network consulting',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/network consulting (1).png"  ></div><div class="col-sm-6">Do you see an endless list of “To Do’s” when you look at your overall IT picture? Are you in need of a comprehensive IT strategic plan that addresses technology transitions, upgrades, and efficient IT processes?</div></div><div>At ISAN Data Systems, we assist you with our extensive IT network consulting expertise to make sure that you implement your IT plan correctly not only the first time but every time.<br><br> Backed by a comprehensive network assessment, we take stock of your inventory and recommend to you a smart, efficient, and cost-effective IT based on your objectives and requirements.</div>'
            }
          ],
          shortDescription: "Do you see an endless list of “To Do’s” when you look at your overall IT picture? Are you in need of a comprehensive IT strategic plan that addresses technology transitions, upgrades, and efficient IT processes?",
          price: 80.00
        },
        virtualizationConsulting: {
          name: 'Virtualization Consulting',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/undraw_secure_server_s9u8.png" class="undraw_secure_server_s9u8"></div><div class="col-sm-6">Our virtualization consultants at ISAN Data Systems have extensive experience at managing, supporting, securing, and optimizing complex data center environments for top businesses.<br><br> It makes them inimitably fit to analyze, design, implement and support the best virtualization solution that can meet your business goals and IT requirements.</div></div>'
            },
            {
              header: 'Flexible, scalable, and economical environments to help you get more out of your hardware investments',
              paragraph: 'Taking into account a holistic view of your organization’s environment, our consultants guide you through the complete virtualization technology life cycle- right from valuations and planning to design and operation. With our commitment to providing the highest standard of support and consultancy services, we help you get the most out of your hardware investments with the use of virtualization technologies'
            }
          ],
          shortDescription: "ISAN Data Systems has extensive experience at managing, supporting, securing, and optimizing complex data center environments for top businesses. It makes them inimitably fit to analyze, design, implement and support the best virtualization solution.",
          price: 80.00
        },
        dataProtectionServices: {
          name: 'Data Protection Consulting',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/Data Protection (4).png"></div><div class="col-sm-6">As an increasing number of enterprises are moving to the Cloud, more data is susceptible to breaches than ever before.<br><br> Whether it is about achieving compliance with the law or taking care of any data breach effectively, our expert consultants are right at the top when it comes to recommending what’s the right thing to do about data protection.</div></div>'
            },
            {
              header: 'We know the Cloud is a minefield, but we can HELP! Protect your data',
              paragraph: '<div class="row description-image"><img  class="col-sm-8 mx-auto" src="./../../assets/images/servies/Data Protection (3).png"></div><div class="row">With a combination of consulting, managed security services, and systems integration, we help you keep your information safe and secure. Backed by a team of experts who know their field fully well, we pride ourselves in being adept at explaining complex legal requirements that can be easily implemented without having any formal education in law or data compliance</div>'
            }
          ],
          shortDescription: "Whether it is about achieving compliance with the law or taking care of any data breach effectively, our expert consultants are right at the top when it comes to recommending what’s the right thing to do about data protection.",
          price: 80.00
        }
      }
    },
    {
      application: {
        websiteDesignAndApplication: {
          name: 'Website design and Development',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/web design and development  (1).png" ></div><div class="col-sm-6">There’s more to a website than an “About Us” and a “Services” page. What actually matters is how you communicate with your audience. Don’t fret; designing your website won’t cost you a fortune.</div></div><div> <br><br>All it takes is finding the right website design and development company and demanding the right features.</div>'
            },
            {
              header: 'Let’s create websites that drive traffic, leads, and conversions.',
              paragraph: '<div class="second-description-image"><img src="./../../assets/images/servies/web design and development  (2).png" ></div><div>With our experienced team of web design professionals to do it for you the right way. The dozens of websites that we have created last year alone would bear testimony to that.<br><br>Our years of successful endeavors at creating the most result-driven websites ensures successful outcomes every time we take on an assignment.</div>'
            }
          ],
          shortDescription: "There’s more to a website than an “About Us” and a “Services” page. What actually matters is how you communicate with your audience. Don’t fret; designing your website won’t cost you a fortune.",
          price: 30.00
        },
        mobileApplication: {
          name: 'Mobile Application',
          description: [
            {
              header: '',
              paragraph: 'Adapting from our extensive experience in creating web solutions for our clients, we are ideally placed to develop targeted mobile application solutions for you.<br><br>Whether you need a standalone product developed particularly for your target mobile audience or a mobile-ready site that can operate in conjunction with your existing web presence, we can build it for you.'
            },
            {
              header: 'Build your business with our 360-degree mobile app solutions',
              paragraph: '<div class="second-description-image"><img src="./../../assets/images/servies/Mobile application (1).png" ></div><div>With our experienced team of web design professionals to do it for you the right way. The dozens of websites that we have created last year alone would bear testimony to that.<br><br>Our years of successful endeavors at creating the most result-driven websites ensures successful outcomes every time we take on an assignment.</div>'
            }
          ],
          shortDescription: "From iOS & Android app development to the development of cloud-based applications, we develop mobile applications for a range of mobile platforms and operating systems",
          price: 30.00
        },
        UIUXDesign: {
          name: 'UX and UI design',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/UI and UX Design (1).png"></div><div class="col-sm-6">Are you planning to get your product built by a team that follows a transparent design process, adheres to deadlines strictly, and delivers the most desirable result? </div></div>'
            },
            {
              header:'Let’s create websites that drive traffic, leads, and conversions.',
              paragraph:'<div class="second-description-image"><img src="./../../assets/images/servies/Mobile application (2).png" ></div><div>Our team of award-winning in-house web and graphic designers fully recognize the fact that visualization, functionality, and usability are three of the most critical factors on a website or an application interface.<br>We have perfected the art of getting delivered to you the product you need, by expert designers, and within the set time frame.</div>'
            }
          ],
          shortDescription: "Our team of award-winning in-house web and graphic designers fully recognize the fact that visualization, functionality, and usability are three of the most critical factors on a website or an application interface.",
          price: 40.00
        },
        eCommerceSolutions: {
          name: 'E-commerce Solutions',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/E - Commerce solutions (1).png"></div><div class="col-sm-6">Backed by a pool of experts, we offer e-commerce services across industry verticals. <br><br>Not only do our solutions cater to an extensive range of industries but are also highly customizable and can be tailored according to your needs and demand</div></div>'
            },
            {
              header:'E-power your Online Trade.',
              paragraph:'<div class="second-description-image"><img src="./../../assets/images/servies/E - Commerce solutions (2).png" ></div><div>While you can depend on us for the delivery of exceptionally strong designs for your online store on the front end, we have the relevant expertise to drive substantial traffic with e-commerce SEO & PPC Campaigns on the backend.<br><br>Thus, we not only have you covered from all sides but play a hand in boosting your growth.</div>'
            }
          ],
          shortDescription: "We, at ISAN Data Systems, deliver e-commerce solutions that encompass every grain in the sand to drive traffic and ensure that sales always meet your growth targets. Backed by a pool of experts, we offer e-commerce services across industry verticals.",
          price: 40.00
        }
      }
    },
    {
      digitalService: {
        artificialIntelligence: {
          name: 'Artificial Services',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/Artificial intelligence (2).png"  ></div><div class="col-sm-6">Emerging as a leading artificial intelligence solution provider, we offer you the most cost-effective services for knowledge virtualization, machine learning, natural language processing, text-to-speech, and much more.</div></div><div>Backed by our team of engineers, consultants, and expert analysts, we perform data visualization, predictive analytics, and promote deep learning by using the most advanced AI technologies.</div>'
            },
            {
              header: 'Driving growth and efficiency with AI-powered applications',
              paragraph: '<div class="second-description-image"><img src="./../../assets/images/servies/Artificial intelligence (1).png"  ></div><div>Driven by our unparalleled passion for developing solutions that focus on real-world issues, we help organizations to embrace AI to focus on innovation, make superior decisions, and gain unmatched efficiencies.<br>We are your go-to partner for pilot projects, develop models and concept prototypes, and tackle industry challenges.</div>'
            }
          ],
          shortDescription: "Driven by our unparalleled passion for developing solutions that focus on real-world issues, we help organizations to embrace AI to focus on innovation, make superior decisions, and gain unmatched efficiencies.",
          price: 99.00
        },
        machineLearning: {
          name: 'Machine Learning',
          description: [
            {
              header: '',
              paragraph: '<div>We help organizations develop custom machine learning solutions that help them craft cognitive strategies for their business growth. With our machine learning-powered business applications, we enable companies in their quest to attain faster decision-making capabilities, enhanced business process automation, more rapid anomaly detection, and increased productivity.</div>'
            },
            {
              header: 'Dynamic machine learning solutions to help you outshine your competitors',
              paragraph: "<div class='second-description-image'><img src='./../../assets/images/servies/Machine learning (1).png'></div><div>Backed by a highly qualified team of technical experts, we create machine learning models that are in line with your business requirements. With our team of data scientists, we deliver end-to-end projects related to machine learning.<br><br> Our machine learning solutions can infuse continuous intelligence in your organization's processes by exponentially boosting business productivity, reducing costs, and improving ROI.</div>"
            }
          ],
          shortDescription: "With our machine learning-powered business applications, we enable companies in their quest to attain faster decision-making capabilities, enhanced business process automation, more rapid anomaly detection, and increased productivity.",
          price: 99.00
        },
        iot: {
          name: 'Internet of Things',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/IOT (2).png"></div><div class="col-sm-6">Our expertise from device to cloud and enterprise solutions to big data analytics places us ideally to architect and implement end-to-end secure commercial IoT solutions. With our solutions that integrate the best of industry components, we offer you seamless actionable insights.</div></div>'
            },
            {
              header: 'Transform your business with smart connected products and new experiences',
              paragraph: '<div class="second-description-image"><img src="./../../assets/images/servies/IOT (1).png"></div><div>Our solution portfolio spans gateway, sensor, system integration, analytics, and cloud solutions. We integrate the best of our processes with industry components to ensure a single point of accountability and deliver complete and end-to-end solutions for a range of verticals. From tracking the traffic density and flow of people to monitoring fluctuations in crop moisture levels, we manage several data points through solutions that are characterized as best industry practices and have the ability to exceed expectations.</div>'
            }
          ],
          shortDescription: "With our solutions that integrate the best of industry components, we offer you sealess actionable insights. Our solution portfolio spans gateway, sensor, system integration, analytics, and cloud solutions.",
          price: 99.00
        },
        digitalMarketing: {
          name: 'Digital Marketing',
          description: [
            {
              header: '',
              paragraph: '<div class="row"><div class="col-sm-6 description-image"><img src="./../../assets/images/servies/digital marketing (2).png"></div><div class="col-sm-6">By leveraging our critical knowledge to gain a deep understanding of the business complexities, we combine the creative and technical aspects of digital marketing to provide you with effective digital marketing solutions that can boost ROI. <br>Thus, we give you a better brand experience in the digital space.</div></div>'
            },
            {
              header: 'Integrated digital marketing solutions to boost your ROI',
              paragraph: '<div class="second-description-image"><img src="./../../assets/images/servies/digital marketing (1).png"></div><div>One of the reasons why we are incredibly confident in our ability to deliver outstanding results is that we have experts in each critical digital channel.<br><br> From SEO and pay-per-click services to social, content, and PR, we work closely with you to ensure that you see the best results across different campaigns through integrated internet marketing strategies.</div>'
            }
          ],
          shortDescription: "By leveraging our critical knowledge to gain a deep understanding of the business complexities, we combine the creative and technical aspects of digital marketing to provide you with effective digital marketing solutions that can boost ROI.",
          price: ''
        }
      }
    }
  ];

  countries: any = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
  ];

  getCountries() {
    return this.countries;
  }
  getListToDisplay(selectedService: any) {
    var arrayToReturn = [];
    this.serviceTypeList.forEach(element => {
      for (const key in element) {
        if (element.hasOwnProperty(key) && key == selectedService) {
          arrayToReturn = element[key];
        }
      }
    });
    return arrayToReturn;
  }

  getServiceListToDisplay(selectedService: any) {
    var arrayToReturn = [];
    this.serviceTypeContent.forEach(element => {
      for (const key in element) {
        if (element.hasOwnProperty(key) && key == selectedService) {
          var subTypeObj = element[key];
          for (const subkey in subTypeObj) {
            if (subTypeObj.hasOwnProperty(subkey)) {
              arrayToReturn.push(subTypeObj[subkey]);
            }
          }
        }
      }
    });
    return arrayToReturn;
  }
  getDescriptionListToDisplay(selectedService: any, selectedSubService: any) {
    var arrayToReturn = [];
    this.serviceTypeContent.forEach(element => {
      for (const key in element) {
        if (element.hasOwnProperty(key) && key == selectedService) {
          var subTypeObj = element[key];
          for (const subkey in subTypeObj) {
            if (subTypeObj.hasOwnProperty(subkey) && subkey == selectedSubService) {
              arrayToReturn = subTypeObj[subkey];
            }
          }
        }
      }
    });
    return arrayToReturn;
  }
  setDescriptionListSelected(serviceDescriptionListSelected: any) {
    this.serviceDescriptionListSelected = serviceDescriptionListSelected;
  }
  getSelectedServiceDescriptionList() {
    return this.serviceDescriptionListSelected;
  }

  constructor() { }

}
