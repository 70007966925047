import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppServiceService } from './app-service.service';
import{Title, Meta} from '@angular/platform-browser'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private appService: AppServiceService, 
    private titleService : Title,
    private meta : Meta
    ) {
      this.titleService.setTitle('ISAN DATA SYSTEMS - Cloud Consulting');
      this.meta.addTag({name:'author', content:'isan data systems'});
      this.meta.addTag({name: 'robots', content:'index, follow'});
      this.meta.updateTag({name: 'keywords', content: 'cloud consulting services, cloud, cloud consulting, cloud computing,aws cloud, cloud migration, cloud consulting companies, best cloud service provider, amzon cloud service'});
      this.meta.updateTag({name: 'description', content: 'We offer a range of cloud consulting services to help enterprises make the most of the cloud. This includes cloud Security, Strategy & Cloud Migration.'});
  
    }
  title = 'frontEnd';
  ngOnInit() {
    let obj = JSON.parse(localStorage.getItem('user_details'));
    if (obj !== undefined) {
      if (new Date(obj.userdata.session_create_time) < new Date()) {
        localStorage.clear();
      } else {
        this.appService.userDetails = JSON.parse(
          localStorage.getItem('user_details')
        );
        this.appService.isUserLoggedIn =
          Object.entries(this.appService.userDetails).length === 0
            ? false
            : true;
      }
    }
   
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0);
    // });
  }
}
