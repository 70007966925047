import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppServiceService } from './../app-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-service-list-description',
  templateUrl: './service-list-description.component.html',
  styleUrls: ['./service-list-description.component.css']
})
export class ServiceListDescriptionComponent implements OnInit {
  serviceSubTypeSelected: any;
  serviceDescriptionList: any;
  serviceFirstTypeSelected: string;
  serviceSubTypeName : string;
  descriptionDetails : any;
  serviceSubTypePriceValue : any;
  constructor(private location: Location,private appService :  AppServiceService,private router : Router) { 
    this.serviceFirstTypeSelected = location.path().split('/')[2];
    this.serviceSubTypeSelected = location.path().split('/')[3];

  }
  getServiceDescriptionList():void{
    this.serviceDescriptionList = this.appService.getDescriptionListToDisplay(this.serviceFirstTypeSelected,this.serviceSubTypeSelected);
    this.serviceSubTypeName = this.serviceDescriptionList.name;
    this.descriptionDetails = this.serviceDescriptionList.description;
    this.serviceSubTypePriceValue = this.serviceDescriptionList.price;
  }
  ngOnInit() {
   this.getServiceDescriptionList();
  }
  onButtonClick(){
    this.appService.setDescriptionListSelected(this.serviceDescriptionList);
    this.router.navigateByUrl('/paymentplan');
  }

}
