import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  images = [
    {
      name: 'Ava Jackson',
      designation: 'Project Lead',
      description:
        "Our Company has been using isandatasystem's managed services from past years. We have found this service to be a very cost effective solution for 24/7/365 replication monitoring and failover testing.",
      image: '/assets/images/testimonials/5.png'
    },
    {
      name: 'Mr. Alok Sahu',
      designation: 'Director, Rinokstrahovka',
      description:
        'As we grow we find we’re always faced with mounting data storage needs and challenges. The people at isandatasystem are courteous and professional, as well as helpful in providing solutions to our data needs.',
      image: '/assets/images/testimonials/2.png'
    },
    {
      name: 'Mr. Pavlo',
      designation: 'Director, Rinokstrahovka',
      description:
        'I am very impressed with the cloud assessment and migration services that ISANDATASYSTEMS provided to our company. Our ability to swiftly add more cloud instances with addition CPU or storage has allowed us to meet our mission critical application demands.',
      image: '/assets/images/testimonials/1.png'
    },
    {
      name: 'Mr. Kshitij Ajagaonkar',
      designation: 'CEO, LogiClicks',
      description:
        'Firstly, ISANDATASYSTEMS helps you to get your HPC environment up and running very quickly. Secondly, Bright makes it incredibly easy to manage your HPC environment which takes a lot of pressure of the IT Services team.',
      image: '/assets/images/testimonials/4.png'
    },
    {
      name: 'Mr. Saksham Kapoor',
      designation: 'Chief Design officer, Rsquaredlabs',
      description:
        'ISANDATASYSTEMS has provided business intelligence services and developed reporting for financial sales analysis for us. They have completed all requests to date. We plan to use ISANDATASYSTEMS services again in the near future.',
      image: '/assets/images/testimonials/3.png'
    }
  ];
  constructor() {}

  ngOnInit() {}
}
