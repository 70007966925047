import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ServicesComponent } from './services/services.component';
import { HeaderComponent } from './header/header.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { ServiceOfHomePageComponent } from './service-of-home-page/service-of-home-page.component';
import { InfrastructureServiceOfHomePageComponent } from './infrastructure-service-of-home-page/infrastructure-service-of-home-page.component';
import { DigitalServiceComponent } from './digital-service/digital-service.component';
import { ApplicationServiceComponent } from './application-service/application-service.component';
import { ExperienceComponent } from './experience/experience.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PricingOfHomePageComponent } from './pricing-of-home-page/pricing-of-home-page.component';
import { CareersComponent } from './careers/careers.component';
import { FooterComponent } from './footer/footer.component';
import { HomeContactUsComponent } from './home-contact-us/home-contact-us.component';
import { ServiceListDisplayComponent } from './service-list-display/service-list-display.component';
import { ServiceListDescriptionComponent } from './service-list-description/service-list-description.component';
// import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { HttpClientModule } from '@angular/common/http';
import { MyAccountComponent } from './my-account/my-account.component';
import { PaymentPlanComponent } from './payment-plan/payment-plan.component';
import { NgxPayPalModule } from 'ngx-paypal';
import {
  DlDateTimeDateModule,
  DlDateTimePickerModule
} from 'angular-bootstrap-datetimepicker';
import { BsDatepickerModule, CarouselModule } from 'ngx-bootstrap';
import { AboutTeamComponent } from './about-team/about-team.component';
import { FaqComponentComponent } from './faq-component/faq-component.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { WhyIsanDataComponent } from './why-isan-data/why-isan-data.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { RefundComponent } from './refund/refund.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ServicesComponent,
    HeaderComponent,
    HomeSliderComponent,
    ServiceOfHomePageComponent,
    InfrastructureServiceOfHomePageComponent,
    DigitalServiceComponent,
    ApplicationServiceComponent,
    ExperienceComponent,
    TestimonialsComponent,
    PricingOfHomePageComponent,
    CareersComponent,
    FooterComponent,
    HomeContactUsComponent,
    ServiceListDisplayComponent,
    ServiceListDescriptionComponent,
    MyAccountComponent,
    PaymentPlanComponent,
    AboutTeamComponent,
    FaqComponentComponent,
    LoadingSpinnerComponent,
    WhyIsanDataComponent,
    ConditionsComponent,
    TermsComponent,
    PolicyComponent,
    RefundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPayPalModule,
    DlDateTimeDateModule, // <--- Determines the data type of the model
    DlDateTimePickerModule,
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot()
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
